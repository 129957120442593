import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import Layout from '../../../layouts/mdx';
export const _frontmatter = {
  "product": "verify",
  "category": "Integrations",
  "sort": 3,
  "title": "AWS Cognito",
  "subtitle": "This tutorial demonstrates how to integrate Criipto Verify with AWS Cognito"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`AWS Cognito basics`}</h2>
    <p>{`Your AWS Cognito user pool must be configured `}<strong parentName="p">{`without`}</strong>{` email as an required attribute.`}</p>
    <p>{`Make sure you have configured a Amazon Cognito domain for your AWS Cognito user pool (in this example we have picked `}<inlineCode parentName="p">{`https://criipto-samples.auth.us-east-1.amazoncognito.com`}</inlineCode>{`)`}</p>
    <h2>{`Register your Criipto Application`}</h2>
    <ol>
      <li parentName="ol">{`Login to you Criipto Verify account - `}<a parentName="li" {...{
          "href": "https://dashboard.criipto.com/applications/add"
        }}>{`https://dashboard.criipto.com/applications/add`}</a></li>
      <li parentName="ol">{`Navigate to Applications`}</li>
      <li parentName="ol">{`Click the "Add login application" button`}</li>
      <li parentName="ol">{`Enter application name (in this example we used "AWS Cognito" and select a domain)`}</li>
      <li parentName="ol">{`Register the callback URL for your AWS Cognito Domain (`}<inlineCode parentName="li">{`https://<your-user-pool-domain>/oauth2/idpresponse`}</inlineCode>{`)`}</li>
      <li parentName="ol">{`Enable OAuth2 Code Flow`}</li>
      <li parentName="ol">{`Create application and make a note of your client id and client secret`}</li>
      <li parentName="ol">{`Modify your "User info response strategy" to "plainJson" and save your application.`}</li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "768px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "93.75%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAATCAYAAACQjC21AAAACXBIWXMAAAsTAAALEwEAmpwYAAACGElEQVQ4y5WUW3PTMBCF9f9/Fw88lMu0HSAwXNpSx47ud0uKcxhJpkNoUsLDN0e7GsvePWuRd7cbvLn5hLe3G1x//ILX7z+g5l5d3bZcjTdfv8NoDq3YPyFKcVgjYIxoWmOtu1oj17yEd+oiiNIKITiUMmNfZiz7rn9T90uuxCPyqikFpNmDOOfApYaQGikl5JzPUnLGYVmOWKoeDsgpIUYPUutWksKo3qPaq5eYoz1JqswOhHEOyhi44OBCIKfwIin589QDaynGelgXYJxfS1ie9HIOSGkGMc1JBW1ke0NOfsW1+H8hxloIqcCEhHUOzjsYZxGCx74k7Eu+jH1ubpMYA2KM8N7DhwAffDs4xIBlKSepDz+ntLEi1WFnBZwRXa2At7LFp1yuw3/aadOUCD6B7rbgdGzK6AhGt2BsxG4a/sh1KN3C2d7vOZ7oIRcMQjAoJS5s/Dp350y5+7nFt/sBPx4G3D+OuB8mPAxjW9e9lqvrx22Lh3E6O9yt5BAjnPMdH5ByeSJXysrvXCnHv99hQSkFO8agtASZg4ZWteHVGNn6452Gt7rnrILRcr1xNKxRaLOrJbReVQkM49BuKHLz+Q4hWEjJWx8rdV0fenIvmLMlxmAQvF7/ZQtydb2BlAzTtAWlExidsKMThKD9ntRiVX6kFa05fD1sdbxCtJz6mNAtOBvBdl0r1vSSO/IZ1sr+de3AbsovSge1vULTv2UAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Create Application Example",
          "title": "Create Application Example",
          "src": "/static/9ee0407f1fa089c306a71db132c4acb9/e5715/create-application.png",
          "srcSet": ["/static/9ee0407f1fa089c306a71db132c4acb9/8514f/create-application.png 192w", "/static/9ee0407f1fa089c306a71db132c4acb9/804b2/create-application.png 384w", "/static/9ee0407f1fa089c306a71db132c4acb9/e5715/create-application.png 768w", "/static/9ee0407f1fa089c306a71db132c4acb9/587b0/create-application.png 970w"],
          "sizes": "(max-width: 768px) 100vw, 768px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span></p>
    <h2>{`AWS Cognito provider setup`}</h2>
    <ol>
      <li parentName="ol">{`Go to your existing or newly created AWS Cognito User Pool.`}</li>
      <li parentName="ol">{`Go to Federation > Identity Providers`}</li>
      <li parentName="ol">{`Click OpenID Connect`}</li>
      <li parentName="ol">{`Enter a provider name (in this example we used "Criipto")`}</li>
      <li parentName="ol">{`Enter your Criipto Application client id in the "Client ID" field`}</li>
      <li parentName="ol">{`Enter your Criipto Application client secret in the "Client Secret" field`}</li>
      <li parentName="ol">{`Enter "openid" in "Authorize scope" field`}</li>
      <li parentName="ol">{`Enter `}<inlineCode parentName="li">{`https://{{YOUR_CRIIPTO_DOMAIN}}`}</inlineCode>{` in the "Issuer" field`}</li>
      <li parentName="ol">{`Click "Run discovery"`}</li>
      <li parentName="ol">{`Click "Create provider"`}</li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "768px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "88.54166666666666%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAASCAYAAABb0P4QAAAACXBIWXMAAAsTAAALEwEAmpwYAAABtElEQVQ4y6WTi5KiMBBF8/+/KDuAjiAveZOkk3inuhF3nFp116XqVEuK3L79UFlr0XWdMI4jLpcLQghvwXeVtQb7/R5FWYrgu2KM9x5qnmfsdjt0fQ9++PD7B845OOdX/AucgyIi1HWN0+mEPM9RliWyLEeWZRjE8UWE/e3SAzZBdsglH4+ZxCSJcW47cCJtjMSNTfgZSmuNNE0RJ4nwEX9IZMfskuNKAWPtrRV/gp2qZVmQpCmqukZZVatQnoMT/e7ha8g5WKLVIfduGAZZHRaR4Txx8ghuizLG4HA4oChKNE0jZf2r0F0PWZAHQuTE2bbYf3OZJ0vf1onLVnpZZLrsbqPvh8eiIUjPeJ1mCmgmQjESqpEwmOtQougX4iRGFEWI4xiL1i8HwAk1eXQLoZ0J55kwWQflvYMxVhrK/bOyb+H+H/KEO/dc8mg8qolQz1cmQjnaO6oH79Vk5Xu5L9FCZYNFpx0m64VeO3y2Bocr+9Yg6+ztbHtPzxrlyGUG6d1CAcfeQhkX0C1ORDd67dEbv8afXM8Hsybffm8o/MfDrpp5nTDDCRTv3btoF0SkXVa4ZV9FcnpggCSy8AAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Create AWS Cognito Provider example",
          "title": "Create AWS Cognito Provider example",
          "src": "/static/7902cf0d7a119ba4635f44963e3570fd/e5715/create-cognito-provider.png",
          "srcSet": ["/static/7902cf0d7a119ba4635f44963e3570fd/8514f/create-cognito-provider.png 192w", "/static/7902cf0d7a119ba4635f44963e3570fd/804b2/create-cognito-provider.png 384w", "/static/7902cf0d7a119ba4635f44963e3570fd/e5715/create-cognito-provider.png 768w", "/static/7902cf0d7a119ba4635f44963e3570fd/c6bbc/create-cognito-provider.png 910w"],
          "sizes": "(max-width: 768px) 100vw, 768px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span></p>
    <h2>{`AWS Cognito application setup`}</h2>
    <ol>
      <li parentName="ol">{`Create a new Cognito app client or use an existing one.`}</li>
      <li parentName="ol">{`Go to "App client settings" and find your Cognito app client.`}</li>
      <li parentName="ol">{`Mark "Criipto" (or whatever provider name you picked for your AWS Cognito provider)`}</li>
      <li parentName="ol">{`Add a callback URL to your application if you have not already`}</li>
      <li parentName="ol">{`Setup OAuth2 scopes`}</li>
      <li parentName="ol">{`Click "Save changes"`}</li>
    </ol>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "768px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "57.29166666666667%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsTAAALEwEAmpwYAAABFklEQVQoz51SW26EMAzM/c/RU/QQ/etPP0GFkgdBiAQICbOyt9lNadmVamlkm5DxxLbYtg0hBJAn7Pt+A5m1Fp9Ng7ZtOSYr/zlC9L2FUhrGGGitEWPEuq5c5C/LhY6F8zfRWwtjekgp0XWSVT5T8VDhMAzQ2mAcR0zTBOccnPfw3t9i9iegO/M83wmbpkFd16wspcRP/he+7wpiJxWUEGFGLOKcnyKmOyE9mWST3JQPC/Ij8TMwodKae+j8tS/UP78G+BAZc0hIxTQfmVBKoaoqdFJiWQMT0spwKxwpTzTy06nSGRWL6epFnhT5eVmYjCfsPZNSTntZrtJxH38o/LW4J085LjDZlnZsMeH9y+HlTeH1Y8AFUUJfN4CZmbwAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "AWS Cognito App client settings",
          "title": "AWS Cognito App client settings",
          "src": "/static/cc73a52069f67ee48c0001900eaf49d4/e5715/cognito-app-settings.png",
          "srcSet": ["/static/cc73a52069f67ee48c0001900eaf49d4/8514f/cognito-app-settings.png 192w", "/static/cc73a52069f67ee48c0001900eaf49d4/804b2/cognito-app-settings.png 384w", "/static/cc73a52069f67ee48c0001900eaf49d4/e5715/cognito-app-settings.png 768w", "/static/cc73a52069f67ee48c0001900eaf49d4/4ad3a/cognito-app-settings.png 1152w", "/static/cc73a52069f67ee48c0001900eaf49d4/71c1d/cognito-app-settings.png 1536w", "/static/cc73a52069f67ee48c0001900eaf49d4/1acf3/cognito-app-settings.png 1596w"],
          "sizes": "(max-width: 768px) 100vw, 768px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span></p>
    <h2>{`Perform a login`}</h2>
    <p>{`Launch the hosted UI for your AWS Cognito domain/client or perform an authorize request.`}</p>
    <h2>{`Common errors`}</h2>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`invalid token_type in idp oauth response`}</inlineCode>{`: Make sure "User info response strategy" is configured as "plainJson"`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      